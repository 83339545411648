.error .background {
  background: url(/public/baggrund.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
  }
  
  .error .logo {
    font-family: 'lato';
    position: absolute;
    right: 60px;
    top:60px;
    color:var(--brandColor);
    z-index: 2;
    font-size: 50px;
    font-weight: bold;
  }
  
  .errorContainer {
    z-index: 2;
    position: relative;
    text-align: center;
    
  }

  .errorMessage {
    color: #fff;
    font-size: 30px;
    background-color: black;
    opacity: 0.80;
    height:100px;
    padding-top: 32px;
    text-align: center;
  }
  
