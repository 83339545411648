@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url(/public/fonts/IBMPlexSans-Regular.ttf);
}

.login .loginBackground {
  /*
  background: url(/public/baggrund.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
 */
}

.login .MobileImage {

  height: 780px;
    
}

.login .MobileContainer {
        /* max-width: 525px; */
        height: 780px;
        /* width: 525px; */
        margin-top: 119px;
}

.login .MobileContainerMobile {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex-wrap: nowrap;
}

.login .loginLogo {
  font-family: 'lato';
    position: absolute;
    right: 60px;
    top:60px;
    color:var(--brandColor);
    z-index: 2;
    font-size: 50px;
    font-weight: bold;
}

.loginContainer {
  padding-left: 120px;
  z-index: 2;
  position: relative;
  
}

@media only screen and (max-width: 900px) {
    .loginContainer {
        padding-left: 40px;
        padding-right: 40px;
    }

    .centerText, .centerVertical {
      justify-content:initial !important;
      align-content:initial;
      flex-direction: column !important;
      padding-top: 125px !important
    }

    .login .MobileContainer {

      margin-top: 0px;
    }
    .login .MobileImage {
      /* height: 780px; */
      
    }
  }

.login .loginContainer h1 {
  color: white;
  font-size: 48px;
  line-height: 54px;
  margin: 0px;
  margin-bottom: 8px;
}

.login .loginContainer h2 {
  color: white;
  font-size: 40px;
  line-height: 46px;
  font-weight: normal;
  text-align: left;
  margin: 0px;
  text-transform: none;
}

.login .loginContainer h3 {
  color: #f0f0f0;
  font-size: 16px;
  line-height: 26px;
  font-weight: lighter;

}

.login .loginContainer .SubHeadingContainer {
  padding-top: 42px;
  max-width: 800px;
  margin-right: 40px;

}

.textContainerHeading {
  font-size: 21px;
  color: white;
  font-weight: normal;
}

.textContainerP {
  color: #f0f0f0;
  font-size: 16px;
}

.login .loginContainer .HeadingContainer {
  max-width: 600px;
}

.login .loginContainer .loginBoxContainer {
  margin-top: 15px;
  /*
  background: #fff;
  border-radius: 10px;
  max-width: 700px;
  min-width: 300px;
  margin-top: 55px;
  margin-right: 40px;
  */
}

.login .loginContainer .loginBoxContainer .textContainer {
  /*
  padding: 50px;
  */
}

.login .loginContainer .loginBoxContainer .loginButton {
  display: flex;
  background: #0060e6;
  color: #ffffff;
  font-family: "IBM Plex Sans", Helvetica, Arial, sans-serif;
  border-radius: 4px;
  height: 48px;
  width: 220px;
  padding: 0.25rem 0.75rem 0.25rem 1rem;
  cursor: pointer;
  border: 0px;
}

.MitID_ButtonLogo {
  height: 15px;
  margin-top: 12px;
  font-size: 14px;
}

.MitID_ButtonText {
  margin-top: 10px;
  padding-left: 14px;
}

@media only screen and (min-width: 900px) {
  .screen {
    display: block;
  }

  .mobile {
    display: none !important;
  }
}
@media only screen and (max-width: 900px) {
  

  .login .loginContainer h2 {
    font-size: 30px;
    line-height: 38px;
  }

  .login .loginContainer h3 {
    font-size: 14px;
    line-height: 20px;
  }

  .login .loginContainer .textContainerHeading {
    font-size: 18px;
    color: white;
    font-weight: normal;
  }

  .login .loginContainer .loginBoxContainer .textContainer {
    /*
    padding: 30px;
    */
  }

  .login .loginContainer .SubHeadingContainer {
    padding-top: 40px;
  }

  .screen {
    display: none;
  }

  .mobile {
    display: block;
  }

  .textContainerP {
    font-size: 14px;
}

.login .loginLogo {
  font-size: 26px;
}
}