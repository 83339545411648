:root {
  --brandColor: white;
  --mainColor: #bcb6af;
  --mainTextColor: white;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans');



@font-face {
  font-family: Lato;
  font-weight: 100;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url(/public/fonts/lato-hairline.woff);
}

@font-face {
  font-family: Lato;
  font-weight: 300;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url(/public/fonts/lato-light.woff);
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url(/public/fonts/lato-normal.woff);
}

@font-face {
  font-family: Lato;
  font-weight: 800;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url(/public/fonts/lato-heavy.woff);
}

* {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Open Sans';
  position: relative;
  top: 0px;
  height: 100%;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  background: url(/public/baggrund.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  overflow-x: hidden;
}

.App-header {
  background-color: rgb(247, 247, 247);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

p {
  font-size: 14px;
}

.centerVertical {
  text-align: left;
}

.centerText, .centerVertical {
  text-align: left !important;
    height: 100vh !important;
    display: flex !important;
    vertical-align: middle !important;
    width: 100vw !important;
    flex-direction: row !important;
    align-content: center !important;
    align-items: center !important;
    justify-content: center !important;
}